import type { Theme } from "@mui/material"
import { extraColors } from "styles/themes"
import { getNumberFromZeroToMaxFromStr, mod } from "helpers/utils/common"

export const getAlertStateColor = (state: string, theme: Theme): string => {
  switch (state) {
    case "triggered":
      return theme.palette.error.main
    default:
      return theme.palette.primary.main
  }
}

export const getBarPlotSeverityColor = (state: string, theme: Theme): string => {
  switch (state) {
    case "low":
      return theme.palette.secondary.main
    case "medium":
      return theme.palette.warning.main
    case "high":
      return theme.palette.error.main
    default:
      return theme.palette.primary.main
  }
}

export const getColorFromString = (str: string): string => {
  return extraColors[getNumberFromZeroToMaxFromStr(str, extraColors.length)]
}

export const getSequentialColor = (index: number): string => {
  const colorIdx = mod(index, extraColors.length)
  return extraColors[colorIdx]
}

export const getAccordionStateColor = (
  state: "open" | "silenced" | "notTriggered",
  theme: Theme,
): string => {
  switch (state) {
    case "open":
      return theme.palette.error.main
    case "silenced":
      return theme.palette.warning.main
    case "notTriggered":
      return theme.palette.action.disabled
    default:
      return theme.palette.action.disabled
  }
}
